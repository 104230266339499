import { Box, Button, CircularProgress, MenuItem } from "@mui/material"
import { FormProvider, RHFSelect, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useEffect, useMemo } from "react"
import { useConfiguredExpenseProviders } from "../../../../queries"
import { useForm } from "react-hook-form"
import { updateExpenseProvider } from "../../../../mutations/expenses"
import { LoadingButton } from "@mui/lab"

interface ProviderPaymentSyncFormProps {
    providerId: string
    onClose?: () => void
}

const ProviderPaymentSyncForm = ({ providerId, onClose }: ProviderPaymentSyncFormProps) => {
    const isMobile = useIsMobile()
    const { data: providers } = useConfiguredExpenseProviders()
    const { translate } = useLocales()
    const { mutate: updateProvider, mutateAsync, isPending: isProviderUpdating } = updateExpenseProvider()
    const currentProvider = useMemo(() => {
        return providers?.find((p) => p.id === providerId)
    }, [providers])
    const methods = useForm({
        defaultValues: {
            arePaymentsSync: currentProvider?.arePaymentsSync ?? false,
        },
    })
    const { handleSubmit, watch, setValue, getValues } = methods

    const onSave = useCallback(async () => {
        if (currentProvider == null) return
        if (currentProvider.arePaymentsSync !== getValues("arePaymentsSync")) {
            await mutateAsync({
                providerId,
                operations: [{ op: "replace", path: "/arePaymentsSync", value: getValues("arePaymentsSync") }],
            })
            if (onClose) {
                onClose()
            }
        }
    }, [currentProvider, mutateAsync, onClose, getValues])

    useEffect(() => {
        const subscription = watch((_) => {
            const isTrue = `${_.arePaymentsSync}` === "true"
            if (currentProvider && isTrue !== currentProvider.arePaymentsSync && !isMobile) {
                updateProvider({
                    providerId,
                    operations: [{ op: "replace", path: "/arePaymentsSync", value: isTrue }],
                })
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, providerId, currentProvider, isMobile])

    useEffect(() => {
        setValue("arePaymentsSync", currentProvider?.arePaymentsSync ?? false)
    }, [currentProvider])

    if (providers == null) return null
    if (isProviderUpdating && !isMobile) return <CircularProgress size='1rem' />

    return (
        <Box display='flex' flexDirection='column' width='100%' sx={{ textAlign: "center" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                {isMobile ? (
                    <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                        <RHFSelect fullWidth name={"arePaymentsSync"} size='small'>
                            <MenuItem value={"true"} style={{ textTransform: "none" }}>
                                {translate("yes")}
                            </MenuItem>
                            <MenuItem value={"false"} style={{ textTransform: "none" }}>
                                {translate("nope")}
                            </MenuItem>
                        </RHFSelect>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingX: 0,
                                paddingTop: 2,
                                gap: 2,
                                flexDirection: isMobile ? "column-reverse" : "row",
                            }}
                        >
                            <Button onClick={onClose} color={"primary"} fullWidth={isMobile} disabled={isProviderUpdating}>
                                {translate("cancel")}
                            </Button>
                            <LoadingButton
                                type={"submit"}
                                variant='contained'
                                color={"primary"}
                                fullWidth={isMobile}
                                loading={isProviderUpdating}
                                disabled={isProviderUpdating}
                            >
                                {translate("update")}
                            </LoadingButton>
                        </Box>
                    </Box>
                ) : (
                    <Box display={"flex"} paddingY={1}>
                        <RHFSelect fullWidth name={"arePaymentsSync"} size='small'>
                            <MenuItem value={"true"} style={{ textTransform: "none" }}>
                                {translate("yes")}
                            </MenuItem>
                            <MenuItem value={"false"} style={{ textTransform: "none" }}>
                                {translate("nope")}
                            </MenuItem>
                        </RHFSelect>
                    </Box>
                )}
            </FormProvider>
        </Box>
    )
}

export default ProviderPaymentSyncForm
