import {
    AccountType,
    RoleName,
    UserFlags,
    useTenantRentingPeriodsQuery,
    useUserDataQueryFn,
    useUserNotificationsQuery,
    useUserPropertiesQuery,
} from "../queries/userData"
import * as Sentry from "@sentry/react"
import { useDispatch, useSelector } from "../redux/store"
import { useCurrencyQueryFn } from "../queries/currency"
import { useUnitsQueryFn } from "../queries/units"
import { updateUserCurrency, useLogout } from "../mutations/user"
import { useChangeLanguage } from "../utils/useChangeLanguage"
import * as jsonpatch from "fast-json-patch"
import React, { ReactElement, useCallback, useEffect, useMemo } from "react"
import {
    AppContext,
    DashboardLayout as DBLayout,
    Iconify,
    Label,
    LoadingScreen,
    NavListProps,
    Option,
    Type,
    useLocales,
    useTypedParams,
} from "rentzz"
import {
    AppModals,
    setContext,
    setCurrentGroupId,
    setCurrentPropertyId,
    setCurrentRentingPeriodId,
    setEditingItem,
    setModalOpen,
} from "../redux/slices/App"
import { useRentingPeriodsWithPaginationQueryFn, useSoonToBeExpiredRentingPeriodsQueryFn } from "../queries/tenants"
import { useTheme } from "@mui/material/styles"
import { tracker } from "../index"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import PWANotifications from "./PWANotifications"
import Breadcrumbs from "./Breadcrumbs"
import { useSearchQueryFn } from "../queries/search"
import NotificationsDrawerContent from "../sections/notifications/NotificationsDrawerContent"
import { IncomesFilteredOptions } from "../utils/atom"
import { useNavigate } from "react-router-dom"
import { getIncomesReportSummaryQueryFn } from "../queries/income"
import { TenantGuide } from "../sections/user/guide/UserHelpGuard"

export const ICONS = {
    tenants: <Iconify icon={"mdi:account-multiple"} />,
    properties: <Iconify icon={"mdi:home-city"} />,
    files: <Iconify icon={"mdi:file-document-multiple"} />,
    income: <Iconify icon={"mdi:finance"} />,
    expense: <Iconify icon={"mdi:tag-multiple"} />,
    dashboard: <Iconify icon={"mdi:speedometer"} />,
    payment: <Iconify icon={"mdi:account-cash"} />,
    promotionalCode: <Iconify icon={"mdi:account-star"} />,
    contracts: <Iconify icon={"mdi:file-document-edit-outline"} />,
    marketing: <Iconify icon={"mdi:shopping-outline"} />,
    insurance: <Iconify icon={"mdi:shield-home"} />,
    meters: <Iconify icon={"mdi:meter-electric"} />,
    notes: <Iconify icon={"mdi:text-box-search"} />,
    calendar: <Iconify icon={"mdi:calendar"} />,
    nomenclature: <Iconify icon={"mdi:folder-settings-variant"} />,
    support: <Iconify icon={"mdi:support"} />,
    providers: <Iconify icon={"mdi:account-tie"} />,
    labels: <Iconify icon={"mdi:label-multiple"} />,
    sections: <Iconify icon={"mdi:subtasks"} />,
    tasks: <Iconify icon={"mdi:format-list-checks"} />,
    invoicingConfiguration: <Iconify icon={"mdi:invoice-text-multiple-outline"} />,
    c168: <Iconify icon={"mdi:invoice-text-edit-outline"} />,
    revision: <Iconify icon={"mdi:wrench"} />,
    group: <Iconify icon={"mdi:house-group"} />,
    contractsTemplates: <Iconify icon={"mdi:contract-sign"} />,
    notificationsTemplates: <Iconify icon={"mdi:note-alert-outline"} />,
    generalDocuments: <Iconify icon={"mdi:file-document-multiple"} />,
    bankAccount: <Iconify icon={"mdi:bank"} />,
}

interface Props {
    children: ReactElement
}

const DashboardLayout = ({ children }: Props) => {
    const navigation = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { handleLangOption } = useChangeLanguage()
    const routeCurrentPropertyId = useTypedParams<number | undefined>("currentPropertyId", Type.Number)
    const routeCurrentGroupRouteId = useTypedParams<number | undefined>("currentGroupId", Type.Number)
    const routeCurrentRentingPeriodId = useTypedParams<number | undefined>("currentRentingPeriodId", Type.Number)
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: user } = useUserDataQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const { data: soonToBeExpiredRentingPeriods } = useSoonToBeExpiredRentingPeriodsQueryFn()
    const { isLoading: isUnitsLoading } = useUnitsQueryFn()
    const { data: currencies, isLoading } = useCurrencyQueryFn()
    const { mutate: updateCurrency } = updateUserCurrency()
    const { data: rentingPeriods } = useRentingPeriodsWithPaginationQueryFn(0, 0, [])
    const { mutate: signOut } = useLogout()
    // const { data: meters } = useMetersQueryFn()
    const policiesPageUrl = useFeatureValue(UserFlags.PoliciesPageUrl.toString(), "")
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const hasColoredBar = useFeatureIsOn(UserFlags.ColoredBar.toString())
    const arePropertyAdsEnabled = useFeatureIsOn(UserFlags.PropertyAd.toString())
    const arePromoCodesEnabled = useFeatureIsOn(UserFlags.PromoCode.toString())
    const appName = useFeatureValue(UserFlags.Name.toString(), "")
    const showPaymentNotifications = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const areTaskItems = useFeatureIsOn(UserFlags.TaskItems.toString())
    const { editableProperties } = usePermissions(PermissionType.Properties)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())
    const arePaymentsHidden = useFeatureIsOn(UserFlags.HideTenantPaymentPage.toString())
    const areRevisionsHidden = useFeatureIsOn(UserFlags.HideTenantRevisions.toString())
    const { data: userNotifications } = useUserNotificationsQuery()
    const { data: incomesSummaryStatus } = getIncomesReportSummaryQueryFn(true)
    const allGuides = useFeatureValue<Record<string, TenantGuide[]>>(UserFlags.TenantGuides, {})
    const newItem = useMemo(() => <Label color={"info"}>{translate("new_feature")}</Label>, [translate])

    useEffect(() => {
        const script = document.createElement("script")
        let isScriptAdded = false

        if (user) {
            if (isWhitelabel && user.accountType === AccountType.WHITELABEL_ADMIN_USER) {
                script.src = "//eu.fw-cdn.com/12853514/823372.js"
                script.async = true
                script.setAttribute("chat", "true")
                isScriptAdded = true
                document.body.appendChild(script)
            }
            tracker.setUserID(user.id)
            Sentry.setUser({ id: user.id, email: user.email })
            Sentry.setTag("appName", appName)
        } else {
            tracker.setUserID("")
            Sentry.setUser(null)
        }

        return () => {
            if (isScriptAdded) document.body.removeChild(script)
        }
    }, [user, appName, isWhitelabel])

    useEffect(() => {
        if (context === AppContext.Owner) {
            dispatch(setCurrentPropertyId(routeCurrentPropertyId))
            dispatch(setCurrentGroupId(routeCurrentGroupRouteId))
            dispatch(setCurrentRentingPeriodId(routeCurrentRentingPeriodId))

            soonToBeExpiredRentingPeriods?.forEach((r) => {
                if (localStorage.getItem(`${r.id}-firstOnRentingPeriodStatusPopup`) === null) {
                    dispatch(setModalOpen(AppModals.RentingPeriodStatus))
                    dispatch(setEditingItem({ rentingPeriodId: r.id }))
                }
            })
        }
    }, [context, dispatch, routeCurrentPropertyId, routeCurrentGroupRouteId, soonToBeExpiredRentingPeriods, routeCurrentRentingPeriodId])

    const tenantNavConfig = useMemo(() => {
        const nav = [
            {
                subheader: translate("general"),
                items: [
                    {
                        title: translate("dashboard"),
                        path: "/dashboard",
                        icon: ICONS.dashboard,
                    },
                    {
                        title: translate("notes.title"),
                        path: "/notes",
                        icon: ICONS.notes,
                    },
                    { title: translate("files"), path: "/documents", icon: ICONS.files },
                    {
                        title: translate("expenses.expenses"),
                        path: "/expenses",
                        icon: ICONS.expense,
                    },
                    { title: translate("meters"), path: "/meters", icon: ICONS.meters },
                ],
            },
        ]
        if (areTaskItems) {
            nav[0].items.splice(1, 0, {
                title: translate("tasks"),
                path: "/tasks",
                icon: ICONS.tasks,
            })
        }
        if (!arePaymentsHidden) {
            nav[0].items.splice(5, 0, {
                title: translate("payment.title"),
                path: "/payments",
                icon: ICONS.payment,
            })
        }
        if (!areRevisionsHidden) {
            nav[0].items.push({
                title: translate("revisions"),
                path: "/maintenance",
                icon: ICONS.revision,
            })
        }

        return nav
    }, [translate, areTaskItems, arePaymentsHidden, areRevisionsHidden])

    const ownerNavConfig: {
        subheader: string
        items: NavListProps[]
    }[] = useMemo(
        () => [
            {
                subheader: translate("general"),
                items: [
                    {
                        title: translate("dashboard"),
                        path: "/dashboard",
                        icon: ICONS.dashboard,
                    },
                    {
                        title: translate("properties"),
                        path: "/properties",
                        icon: ICONS.properties,
                    },
                    { title: translate("files"), path: "/documents", icon: ICONS.files },
                    {
                        title: translate("expenses.expenses"),
                        path: "/expenses",
                        icon: ICONS.expense,
                    },
                    { title: translate("income"), path: "/income", icon: ICONS.income },
                    {
                        title: translate("renting_periods"),
                        path: "/tenants",
                        icon: ICONS.tenants,
                    },
                    {
                        title: translate("insurance.name"),
                        path: "/insurance",
                        icon: ICONS.insurance,
                    },
                    {
                        title: translate("revisions"),
                        path: "/revisions",
                        icon: ICONS.revision,
                    },
                    { title: translate("groups"), path: "/groups", icon: ICONS.group },
                ],
            },
        ],
        [translate],
    )

    const getNavConfig = useCallback(() => {
        if (context === AppContext.Tenant) return tenantNavConfig

        const documentsChildren = [
            {
                title: translate("template"),
                path: "/document-templates/contract-templates",
                icon: ICONS.contractsTemplates,
            },
            {
                title: translate("general_documents"),
                path: "/document-templates/general-documents",
                icon: ICONS.generalDocuments,
            },
        ]

        if (showPaymentNotifications) {
            documentsChildren.push({
                title: translate("notifications_templates"),
                path: "/document-templates/notification-templates",
                icon: ICONS.notificationsTemplates,
            })
        }

        if (context === AppContext.Owner && (!isWhitelabel || (isWhitelabel && user?.accountType === AccountType.WHITELABEL_ADMIN_USER))) {
            ownerNavConfig[0].items.push({
                title: translate("documents_type"),
                path: "/document-templates/contract-templates",
                icon: ICONS.contracts,
                children: documentsChildren,
                info: newItem,
            })
        }

        if (ownerNavConfig[0].items.length === 0) {
            ownerNavConfig.splice(-1)
        }
        if (user?.promoCode && context === AppContext.Owner && arePromoCodesEnabled && !isWhitelabel) {
            ownerNavConfig[0].items.push({
                title: translate("promotional_code"),
                path: "/promo-code",
                icon: ICONS.promotionalCode,
            })
        }
        if (arePropertyAdsEnabled && context === AppContext.Owner) {
            ownerNavConfig[0].items.push({
                title: translate("marketing.name"),
                path: "/marketing",
                icon: ICONS.marketing,
            })
        }

        const nomenclatureChildren = [
            {
                title: translate("labels"),
                path: "/nomenclature/labels",
                icon: ICONS.labels,
            },
            {
                title: translate("providers"),
                path: "/nomenclature/providers",
                icon: ICONS.providers,
            },
        ]
        if (user?.roles.roleName !== RoleName.FREE) {
            nomenclatureChildren.push({
                title: translate("bank-accounts"),
                path: "/nomenclature/bank-accounts",
                icon: ICONS.bankAccount,
            })
        }

        if (areTaskItems) {
            ownerNavConfig[0].items.splice(2, 0, {
                title: translate("tasks"),
                path: "/tasks",
                icon: ICONS.tasks,
            })
            nomenclatureChildren.push({
                title: translate("task_sections"),
                path: "/nomenclature/sections",
                icon: ICONS.sections,
            })
        }

        if (areInvoicingConfigurationOn) {
            nomenclatureChildren.push({
                title: translate("invoicing_configuration"),
                path: "/nomenclature/invoicing-configuration",
                icon: ICONS.invoicingConfiguration,
            })
        }
        if (!isWhitelabel || (isWhitelabel && user?.accountType === AccountType.WHITELABEL_ADMIN_USER)) {
            nomenclatureChildren.push({
                title: "C168",
                path: "/nomenclature/C168",
                icon: ICONS.c168,
            })
        }

        ownerNavConfig[0].items.push({
            title: translate("calendar.menu_name"),
            path: "/calendar",
            icon: ICONS.calendar,
        })
        if (!isWhitelabel || (isWhitelabel && user?.accountType === AccountType.WHITELABEL_ADMIN_USER)) {
            ownerNavConfig[0].items.push({
                title: translate("nomenclature"),
                path: "/nomenclature",
                icon: ICONS.nomenclature,
                children: nomenclatureChildren,
                info: newItem,
            })
        }

        return ownerNavConfig
    }, [user, translate, isWhitelabel, arePropertyAdsEnabled, areTaskItems, areInvoicingConfigurationOn, showPaymentNotifications, context])

    const handleOptionSelect = (currency: { id: number }) => {
        const operations = jsonpatch.compare(
            {
                userPreferences: {
                    currencyId: user?.currency?.id,
                },
            },
            {
                userPreferences: {
                    currencyId: currency.id,
                },
            },
        )
        updateCurrency({
            operations: operations.map((o) => ({ ...o, path: `${o.path}` })),
        })
    }

    // const currentMeters = useMemo(
    //     () =>
    //         meters?.map((meter) => {
    //             return {
    //                 name: meter?.name,
    //                 icon:
    //                     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //                     // @ts-ignore
    //                     // TODO
    //                     meter?.lastReading.startOf("month").monthShort === DateTime.now().startOf("month").monthShort
    //                         ? "mdi:checkbox-marked-circle"
    //                         : "mdi:information-slab-circle",
    //                 onClick: () => {
    //                     dispatch(setModalOpen(AppModals.MeterValuesHistory))
    //                     dispatch(setEditingItem(meter))
    //                 },
    //                 iconColor:
    //                     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //                     // @ts-ignore
    //                     // TODO
    //                     meter?.lastReading.startOf("month").monthShort === DateTime.now().startOf("month").monthShort
    //                         ? theme.palette.primary.main
    //                         : theme.palette.info.main,
    //             }
    //         }),
    //     [meters, dispatch],
    // )

    const getTooltipIncomeTitle = useCallback(() => {
        if (userProperties?.length === 0) return "no_property_no_income"
        if (rentingPeriods?.count === 0) return "tenant_required_income"
        return ""
    }, [userProperties, rentingPeriods])

    const ownerActions = useMemo(() => {
        return [
            {
                name: "expenses.addExpense",
                icon: "mdi:tag-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddExpense)),
                isDisabled: userProperties?.length === 0,
                disabledText: "no_property_no_expense",
            },
            {
                name: "recurringExpenses.add_recurring_expense",
                icon: "mdi:tag-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddRecurringExpense)),
                isDisabled: userProperties?.length === 0,
                disabledText: "no_property_no_expense",
            },
            {
                name: "income-table.addIncome",
                icon: "mdi:cash-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddIncome)),
                isDisabled: userProperties?.length === 0 || rentingPeriods?.count === 0,
                disabledText: getTooltipIncomeTitle(),
            },
            {
                name: "add_document",
                icon: "mdi:file-document-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddDocument)),
                isDisabled: userProperties?.length === 0,
                disabledText: "no_property_no_document",
            },
        ]
    }, [dispatch, rentingPeriods, context])

    const tenantActions = useMemo(
        () => [
            {
                name: "payment.addPayment",
                icon: "mdi:database-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddPayment)),
                isDisabled: false,
                disabledText: "",
            },
            // {
            //     name: "add_reading",
            //     icon: "mdi:timer-plus-outline",
            //     onClick: console.log,
            //     isDisabled: meters?.length === 0,
            //     disabledText: "no_meters_no_reading",
            //     subOptions: currentMeters,
            // },
        ],
        [dispatch],
    )

    const options = useMemo((): Option[] => {
        const toReturn = [
            {
                icon: "mdi:help",
                text: translate("help"),
                hasDivider: true,
                onClick: () => dispatch(setModalOpen(AppModals.Help)),
                color: theme.palette.primary.dark,
            },
            {
                icon: "mdi:cog",
                text: translate("settings"),
                hasDivider: true,
                onClick: () => navigation("/settings"),
                color: theme.palette.primary.dark,
            },
            {
                icon: "mdi:email",
                text: translate("contact"),
                hasDivider: true,
                color: theme.palette.primary.dark,
                onClick: () => dispatch(setModalOpen(AppModals.Contact)),
            },
            {
                icon: "mdi:account-reactivate",
                text: translate("change_context"),
                hasDivider: true,
                onClick: () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentGroupId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                },
                color: theme.palette.primary.dark,
            },
            {
                icon: "mdi:text-box-outline",
                text: translate("policies"),
                hasDivider: true,
                onClick: () => window.open(policiesPageUrl),
                color: theme.palette.primary.dark,
            },
            {
                icon: "mdi:logout",
                text: translate("logout"),
                hasDivider: false,
                onClick: async () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentPropertyId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                    signOut()
                },
                color: theme.palette.error.main,
            },
        ]

        if (isWhitelabel) {
            toReturn.splice(0, 4, toReturn[0], toReturn[1], toReturn[2])
        }
        if (context === AppContext.Owner || (allGuides && allGuides["ro"].length === 0)) {
            toReturn.splice(0, 1)
        }
        if (policiesPageUrl.length === 0) {
            toReturn.slice(
                toReturn.findIndex((i) => i.text === translate("policies")),
                1,
            )
        }
        return toReturn
    }, [translate, signOut, dispatch, policiesPageUrl, isWhitelabel, context, allGuides])

    if (isLoading || isUnitsLoading || context == null || (context === AppContext.Tenant && currentPropertyId == null)) {
        return <LoadingScreen />
    }

    return (
        <>
            <DBLayout
                hasColoredBar={hasColoredBar}
                mail={user?.email ?? ""}
                roleName={isWhitelabel ? undefined : user?.roles.roleName}
                validUntil={isWhitelabel || user?.roles.roleName === RoleName.FREE ? undefined : user?.roles.to}
                navbarConfig={getNavConfig()}
                getLevelColor={"primary"}
                fullName={`${user?.firstName} ${user?.lastName}`}
                currentCurrency={user?.currency}
                currencies={currencies ?? []}
                onCurrencySelect={handleOptionSelect}
                onChangeLang={handleLangOption}
                options={options}
                isTrial={user?.roles.isTrial}
                canTrial={user?.canTrial}
                showTrialAlerts={context === AppContext.Owner}
                quickActions={context === AppContext.Tenant ? tenantActions : ownerActions}
                areQuickActionsAvailable={editableProperties != null && editableProperties.length > 0}
                isExpired={user?.isSubscriptionExpired}
                useSearchQuery={context === AppContext.Owner ? useSearchQueryFn : undefined}
                drawerContent={context === AppContext.Owner ? <NotificationsDrawerContent /> : undefined}
                numberOfNotifications={
                    (userNotifications?.pendingExpensesToAccept ?? 0) +
                    (userNotifications?.pendingReadingsToSend ?? 0) +
                    ((incomesSummaryStatus ?? [])[IncomesFilteredOptions.Pending]?.count ?? 0) +
                    (soonToBeExpiredRentingPeriods?.length ?? 0)
                }
                openMobileNotificationSheet={() => dispatch(setModalOpen(AppModals.NotificationsList))}
                handleTenantChooseProperties={context === AppContext.Tenant ? () => dispatch(setCurrentRentingPeriodId(undefined)) : undefined}
                isChoosePropertiesDisabled={tenantRentingPeriods?.length === 1}
            >
                <PWANotifications />
                {context === AppContext.Owner && <Breadcrumbs />}
                {children}
            </DBLayout>
        </>
    )
}

export default DashboardLayout
