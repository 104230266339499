import { Box, IconButton, Paper, Typography, useTheme } from "@mui/material"
import { Iconify, LightTooltip, useLocales } from "rentzz"
import { usePropertyDetailsQueryFn } from "../../../../queries"
import React from "react"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"

const PropertyDetailsCard = () => {
    const { data: propertyDetails } = usePropertyDetailsQueryFn()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const propertiesToTake = ["county", "city", "street", "streetNumber", "buildingNumber", "stair", "floor", "apartment", "rooms", "year", "size"]
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { isPropertyEditable } = usePermissions(PermissionType.Properties, currentPropertyId)

    return (
        <Paper elevation={6}>
            <Box display='flex' flexDirection='column' height={295}>
                {isPropertyEditable && (
                    <LightTooltip title={translate("edit")} arrow>
                        <span style={{ alignSelf: "flex-end" }}>
                            <IconButton sx={{ padding: 1 }} onClick={() => dispatch(setModalOpen(AppModals.EditProperty))} color='primary'>
                                <Iconify icon={"mdi:home-edit"} sx={{ height: 20, width: 20 }} />
                            </IconButton>
                        </span>
                    </LightTooltip>
                )}

                <Box
                    display='flex'
                    flexWrap={"wrap"}
                    justifyContent={"center"}
                    gap={1}
                    height={260}
                    flexDirection='row'
                    textAlign={"center"}
                    paddingBottom={1}
                    marginTop={isPropertyEditable ? undefined : 2}
                >
                    {propertiesToTake.map((p, index) => (
                        <Box
                            key={index}
                            display='flex'
                            flexDirection='column'
                            justifyContent='space-between'
                            maxWidth={"25%"}
                            minWidth={"25%"}
                            height={40}
                        >
                            <Typography
                                letterSpacing={0.5}
                                textTransform='uppercase'
                                fontWeight={800}
                                fontSize={13}
                                color={theme.palette.primary.dark}
                            >
                                {translate(p)}
                            </Typography>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Typography
                                    variant={"body2"}
                                    sx={{
                                        display: "-webkit-box",
                                        overflow: "hidden",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                        width: p !== "size" ? 100 : undefined,
                                    }}
                                >
                                    {/* eslint-disable-next-line */}
                                    {/* @ts-ignore */}
                                    {propertyDetails[p] || "-"}{" "}
                                </Typography>
                                {propertyDetails && p === "size" && propertyDetails[p] != null && (
                                    <Typography marginLeft={0.5} fontSize={13} paddingBottom={0.2}>
                                        m<sup>2</sup>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Paper>
    )
}

export default PropertyDetailsCard
