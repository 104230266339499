import { Box, Paper } from "@mui/material"
import { useIsMobile, useLocales } from "rentzz"
import { usePropertyDetailsQueryFn } from "../../../../queries"
import { UserFlags, useUserDataQueryFn, useUserPropertiesQuery } from "../../../../queries/userData"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../queries/currency"
import { useIntl } from "react-intl"
import DetailRow from "./DetailRow"
import { ProviderType } from "../../../../redux/slices/AddProperty"
import { useMetersQueryFn } from "../../../../queries/meters"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function PropertyActions() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: propertyDetails } = usePropertyDetailsQueryFn()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery(false)
    const { data: user } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const { data: meters } = useMetersQueryFn()
    const { canWrite } = usePermissions(PermissionType.Properties)
    const areMetersProvidersEnabled = useFeatureIsOn(UserFlags.MeterProviders.toString())
    const areExpensesProvidersEnabled = useFeatureIsOn(UserFlags.ExpenseProviders.toString())

    const currentRentPrice = useMemo(() => userProperties?.find((p) => p.id === currentPropertyId)?.rentPrice, [userProperties, currentPropertyId])
    const currentCurrencyId = useMemo(() => userProperties?.find((p) => p.id === currentPropertyId)?.currencyId, [userProperties, currentPropertyId])
    const currency = useMemo(() => currencyData?.find((c) => c.id === currentCurrencyId)?.code, [currencyData, currentCurrencyId])

    const getCurrency = useCallback(
        (value: number | undefined) => {
            const currency = user?.currency.id
            return intl.formatNumber(Number(value ?? 0), {
                style: "currency",
                currency: getCurrencyFromId(currencies, currency).code,
            })
        },
        [user, intl, getCurrencyFromId, currencies],
    )

    return (
        <Paper elevation={6}>
            <Box
                height={isMobile ? undefined : 295}
                display='flex'
                flexDirection='column'
                gap={1}
                sx={{ padding: 3, overflowY: isMobile ? undefined : "scroll" }}
                justifyContent={"center"}
            >
                <DetailRow
                    title={"totalExpenses"}
                    value={getCurrency(propertyDetails?.totalExpenses)}
                    onAdd={canWrite ? () => dispatch(setModalOpen(AppModals.AddExpense)) : undefined}
                    tooltipTitle={"expenses.addExpense"}
                />
                <DetailRow
                    title={"totalIncome"}
                    value={getCurrency(propertyDetails?.totalIncome)}
                    onAdd={canWrite ? () => dispatch(setModalOpen(AppModals.AddIncome)) : undefined}
                    tooltipTitle={"income-table.addIncome"}
                />

                <DetailRow
                    title={"numberOfMeters"}
                    value={
                        areMetersProvidersEnabled
                            ? `${propertyDetails?.numberOfConfiguredMeterProviders}/${propertyDetails?.numberOfMeters}`
                            : propertyDetails?.numberOfMeters
                    }
                    onAdd={
                        canWrite
                            ? () => {
                                  if (meters?.length === 0 || !areMetersProvidersEnabled) {
                                      dispatch(setModalOpen(AppModals.AddMeter))
                                  } else {
                                      dispatch(setModalOpen(AppModals.AddMeterProvider))
                                      dispatch(setEditingItem({ providerType: ProviderType.Meter, areAllMeters: true }))
                                  }
                              }
                            : undefined
                    }
                    tooltipTitle={meters?.length === 0 || !areMetersProvidersEnabled ? "add_meter" : "add_provider"}
                    valueTooltipTitle={
                        areMetersProvidersEnabled
                            ? translate(
                                  propertyDetails?.numberOfConfiguredMeterProviders === 0
                                      ? "configuredMeterProvidersTooltipZero"
                                      : "configuredMeterProvidersTooltipMore",
                                  { providers: propertyDetails?.numberOfConfiguredMeterProviders, meters: propertyDetails?.numberOfMeters },
                              )
                            : ""
                    }
                />
                {areExpensesProvidersEnabled && (
                    <DetailRow
                        title={"numberOfConfiguredProviders"}
                        value={propertyDetails?.numberOfConfiguredProviders}
                        onAdd={
                            canWrite
                                ? () => {
                                      dispatch(setModalOpen(AppModals.AddExpenseProvider))
                                      dispatch(setEditingItem({ providerType: ProviderType.Expense }))
                                  }
                                : undefined
                        }
                        tooltipTitle={"add_provider"}
                    />
                )}

                <DetailRow
                    title={"numberOfRecurringExpenses"}
                    value={propertyDetails?.numberOfRecurringExpenses}
                    onAdd={canWrite ? () => dispatch(setModalOpen(AppModals.AddRecurringExpense)) : undefined}
                    tooltipTitle={"recurringExpenses.add_recurring_expense"}
                />
                <DetailRow
                    title={"numberOfNotes"}
                    value={propertyDetails?.numberOfNotes}
                    onAdd={canWrite ? () => dispatch(setModalOpen(AppModals.AddNote)) : undefined}
                    tooltipTitle={"notes.addNote"}
                />

                <DetailRow
                    title={"rent_price"}
                    value={
                        currentRentPrice != null && currency != null
                            ? `${intl.formatNumber(currentRentPrice, { style: "currency", currency })}`
                            : translate("still_no_tenant")
                    }
                />
            </Box>
        </Paper>
    )
}
