import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel, MuiEvent, useGridApiRef } from "@mui/x-data-grid-pro"
import ActionWithTooltip from "../../../../utils/ActionWithTooltip"
import { RentingPeriod, RentingPeriodStatusCode } from "../../../../redux/slices/AddProperty"
import { CustomDataGrid, Iconify, Label, LightTooltip, LoadingIcon, RoleName, useLocales } from "rentzz"
import { useCurrencyQueryFn } from "../../../../queries/currency"
import { DateTime } from "luxon"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AccountType, NotificationTemplateVariable, UserFlags, useUserDataQueryFn, useUserPropertiesQuery } from "../../../../queries/userData"
import NoDataPage from "../../../../components/NoDataPage"
import { arrayOfNumbersToPeriod } from "../../../../utils/dateMagic"
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import { useRentingPeriodsWithPaginationQueryFn } from "../../../../queries/tenants"
import DesktopRentingPeriodStatus from "./DesktopRentingPeriodStatus"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import DeleteIcon from "@mui/icons-material/Delete"
import { DESKTOP_ICON_SIZE } from "../../../../utils/helpers"
import NoPropertyPage from "../../../../guards/no-property/NoPropertyPage"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { useAtomValue } from "jotai"
import { rentingPeriodFilterState } from "../../../../utils/atom"
import EventNoteIcon from "@mui/icons-material/EventNote"
import Api from "../../../../api/Api"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import { useSnackbar } from "notistack"
import ChangePlanTooltipComponent from "../../../../components/ChangePlanTooltipComponent"
import CustomToolbar from "../../../../components/CustomToolbar"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import HeaderRentingPeriods from "../HeaderRentingPeriods"
import { Divider } from "@mui/material"

interface DesktopTenantsProps {
    getAmount: (value: number, currencyId?: number) => string
}

export default function DesktopRentingPeriodsList({ getAmount }: DesktopTenantsProps) {
    const { currentPropertyId } = useSelector((state) => state.appState)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const filterModel = useAtomValue(rentingPeriodFilterState)
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "moveInDate", sort: "desc" }])
    const { data: tenants, isFetching: areTenantsLoading } = useRentingPeriodsWithPaginationQueryFn(
        paginationModel.page,
        paginationModel.pageSize,
        sortModel,
        filterModel,
    )
    const api = useGridApiRef()
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate, currentLang } = useLocales()
    const { data: currencyData, isLoading: isCurrencyDataLoading } = useCurrencyQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const { enqueueSnackbar } = useSnackbar()
    const { data: userData } = useUserDataQueryFn()
    const canDeleteRentingPeriod = useFeatureIsOn(UserFlags.CanDeleteRentingPeriod.toString())
    const showPaymentNotifications = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const { canWrite } = usePermissions(PermissionType.RentingPeriods)

    useEffect(() => {
        if (tenants?.items.length === 0 && tenants.count === 0 && api.current?.setRows) api.current?.setRows([])
    }, [tenants])

    const onNavigate = useCallback(
        (params: GridRowParams, event: MuiEvent<MouseEvent> | React.MouseEvent) => {
            event.stopPropagation()

            if (currentPropertyId) {
                navigate(`/properties/${currentPropertyId}/tenants/${params.id}/general`)
            } else {
                navigate(`/tenants/${params.id}/general`)
            }
        },
        [navigate, currentPropertyId],
    )

    const sendNotificationTooltipTitle = useCallback(
        (status: RentingPeriodStatusCode) => {
            if (userData?.roles.roleName != null && ![RoleName.GOLD].includes(userData?.roles.roleName)) {
                return <ChangePlanTooltipComponent />
            }
            if (status === RentingPeriodStatusCode.Accepted || status === RentingPeriodStatusCode.PartiallyAccepted)
                return translate("send_notification")
            else return translate("have_to_be_accepted")
        },
        [userData, translate],
    )

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            type: "actions",
            minWidth: 200,
            flex: 0.1,
            headerName: translate("tenant-data.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    key: "payment",
                    tooltipText:
                        params.row.paymentNotificationVariables.length === 0 ||
                        params.row.paymentNotificationVariables.find((v: NotificationTemplateVariable) => v.value == null || v.value === "")
                            ? translate("please_fill_payment_notification_data")
                            : translate("generate_payment_notification"),
                    hidden: !showPaymentNotifications,
                    element: (
                        <GridActionsCellItem
                            label={"Payment"}
                            disabled={
                                params.row.paymentNotificationVariables.length === 0 ||
                                params.row.paymentNotificationVariables.find((v: NotificationTemplateVariable) => v.value == null || v.value === "")
                            }
                            onClick={async () => {
                                enqueueSnackbar(translate("generating"))
                                const notificationPreview = await Api.fetchGeneralRentingPeriodNotificationPreview(params.row.id, false)
                                const url = window.URL.createObjectURL(new Blob([notificationPreview]))
                                dispatch(
                                    setEditingItem({
                                        url: url,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.PdfViewer))
                            }}
                            icon={
                                <EventNoteIcon
                                    color={
                                        params.row.paymentNotificationVariables.length === 0 ||
                                        params.row.paymentNotificationVariables.find(
                                            (v: NotificationTemplateVariable) => v.value == null || v.value === "",
                                        )
                                            ? "disabled"
                                            : "primary"
                                    }
                                    sx={{ fontSize: DESKTOP_ICON_SIZE }}
                                />
                            }
                        />
                    ),
                }),
                ActionWithTooltip({
                    key: "notification",
                    tooltipText: sendNotificationTooltipTitle(params.row?.rentingPeriodStatus),
                    element: (
                        <GridActionsCellItem
                            label={"Details"}
                            disabled={
                                !(
                                    params.row?.rentingPeriodStatus === RentingPeriodStatusCode.Accepted ||
                                    params.row.rentingPeriodStatus === RentingPeriodStatusCode.PartiallyAccepted
                                ) ||
                                (userData?.roles.roleName != null && ![RoleName.GOLD].includes(userData?.roles.roleName))
                            }
                            onClick={() => {
                                if (userData?.roles.roleName != null && ![RoleName.GOLD].includes(userData?.roles.roleName)) {
                                    enqueueSnackbar(translate("change_plan_snackbar_text"), { variant: "error" })
                                } else {
                                    dispatch(setModalOpen(AppModals.SendTenantNotification))
                                    dispatch(setEditingItem({ rentingPeriodId: params.row.id }))
                                }
                            }}
                            icon={
                                <ForwardToInboxIcon
                                    color={
                                        (params.row?.rentingPeriodStatus === RentingPeriodStatusCode.Accepted ||
                                            params.row.rentingPeriodStatus === RentingPeriodStatusCode.PartiallyAccepted) &&
                                        !(userData?.roles.roleName != null && ![RoleName.GOLD].includes(userData?.roles.roleName))
                                            ? "primary"
                                            : "disabled"
                                    }
                                    sx={{ fontSize: DESKTOP_ICON_SIZE }}
                                />
                            }
                        />
                    ),
                }),

                ActionWithTooltip({
                    key: "delete",
                    tooltipText: translate("delete"),
                    hidden: !params.row.canDelete || !canDeleteRentingPeriod,
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteTenant))
                                dispatch(setEditingItem({ rentingPeriodId: params.id, propertyId: params.row.properyId }))
                            }}
                            label={translate("delete_renting_period")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
                ActionWithTooltip({
                    element: <Iconify icon={"mdi:account-lock"} color={theme.palette.error.main} />,
                    tooltipText: translate("only_read_no_write"),
                    key: "unavailable",
                    hidden: params.row.canDelete && canDeleteRentingPeriod,
                }),
            ],
        }),
        [translate, currencyData, canDeleteRentingPeriod],
    )

    const infoColumn = useMemo(
        (): GridColDef => ({
            field: "____",
            type: "actions",
            headerClassName: "hideRightSeparator",
            width: 50,
            headerName: "",
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    key: "details",
                    tooltipText: translate("renting_period_info"),
                    element: (
                        <GridActionsCellItem
                            label={"Details"}
                            onClick={(e) => onNavigate(params, e)}
                            icon={<RemoveRedEyeIcon color={"primary"} sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                        />
                    ),
                }),
            ],
        }),
        [translate, theme, currentPropertyId],
    )

    const tenantsColumns = useMemo(() => {
        let columns: GridColDef<RentingPeriod>[] = [
            {
                field: "propertyId",
                type: "string",
                minWidth: 120,
                flex: 0.6,
                headerAlign: "center",
                align: "center",
                headerName: translate("property"),
                valueFormatter: (value) => {
                    return userProperties?.find((p) => p.id === value)?.label
                },
            },
            {
                field: "rentingPeriodStatus",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                headerName: translate("tenant-data.rentingPeriodStatus"),
                renderCell: ({ value }) => <DesktopRentingPeriodStatus value={value} />,
            },
            {
                field: "moveInDate",
                minWidth: 120,
                headerAlign: "center",
                flex: 0.3,
                align: "center",
                type: "date",
                headerName: translate("tenant-data.moveInDate"),
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "moveOut",
                filterable: true,
                minWidth: 120,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                type: "date",
                headerName: translate("tenant-data.moveOutDate"),
                valueFormatter: (value, row) => (row.moveOutDate as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "paymentPeriod",
                minWidth: 100,
                headerAlign: "center",
                align: "center",
                headerName: translate("payment.title"),
                renderCell: ({ value }) => (
                    <LightTooltip title={""} arrow>
                        <Label color={"primary"} style={{ textTransform: "none" }}>
                            {arrayOfNumbersToPeriod(value ?? []).join(", ")}
                        </Label>
                    </LightTooltip>
                ),
            },
            {
                field: "value",
                flex: 0.3,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                filterable: true,
                type: "number",
                headerName: translate("tenant-data.price"),

                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "securityDeposit",
                flex: 0.3,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                filterable: true,
                type: "number",
                headerName: translate("tenant-data.securityDeposit"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.securityDepositCurrencyId as number) || "-"
                },
            },
        ]

        if (filterModel.items.length !== 0) {
            columns = columns.filter((c) => c.field !== "rentingPeriodStatus")
        }

        if (currentPropertyId) {
            return columns.filter((c) => c.field !== "propertyId")
        }
        return columns
    }, [currentPropertyId, tenants, currentLang, currencyData, userProperties])

    if (isCurrencyDataLoading || tenants == null) return <LoadingIcon />
    if (tenants?.count === 0 && userData?.accountType === AccountType.WHITELABEL_USER) {
        return <NoPropertyPage />
    }

    return (
        <>
            <CustomToolbar
                onAddClick={canWrite ? () => dispatch(setModalOpen(AppModals.CreateRentingPeriod)) : undefined}
                numberOfItems={areTenantsLoading ? 0 : tenants.count}
                buttonText={"add_new_renting_period"}
            >
                <HeaderRentingPeriods />
                <Divider />
            </CustomToolbar>
            <CustomDataGrid
                apiRef={api}
                paginationMode={tenants.count === 20 && tenants.items.length === 0 ? "client" : "server"}
                hidePagination={tenants.count === 20 && tenants.items.length === 0}
                isLoading={areTenantsLoading && !(tenants.count === 20 && tenants.items.length === 0)}
                rowsLoadingMode={tenants.count === 20 && tenants.items.length === 0 ? "server" : "client"}
                rows={tenants.items ?? []}
                columns={tenantsColumns}
                actionsColumn={[actionsColumn]}
                infoColumn={[infoColumn]}
                idKey={"id"}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"mdi:account-off"} noDataText={"no_tenants"} />,
                }}
                onRowDoubleClick={onNavigate}
                paginationModel={paginationModel}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={tenants.count}
                filterModel={filterModel}
                onPaginationModelChange={setPaginationModel}
            />
        </>
    )
}
